import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { add } from 'ngx-bootstrap/chronos';
import { ButtonCardType } from '../../../features/funding-appeal/funding-appeals-constants';

@Component({
  selector: 'fc-choose-button',
  templateUrl: './choose-button.component.html',
  styleUrls: ['./choose-button.component.scss']
})
export class ChooseButtonComponent {
  @Output() clickAction: EventEmitter<ButtonCardType | boolean> = new EventEmitter<ButtonCardType | boolean>();
  title: string;
  revertKey: string;
  buttons: {
    key: string;
    value: string;
  }[] = [];
  colClass = 'col-3';
  theme: 'primary' | 'danger' = 'primary';

  constructor(public activeModal: NgbActiveModal) {}
}
